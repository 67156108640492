  
  .mainContainer {
    margin-top: 5rem;
  }

  .headerContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 55px;
    background-color: #2b2c44;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .inactiveContainer {
    margin: 10rem;
  } 

  .infoContainerInner {
    padding: .6rem 1.5rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    row-gap: .2rem;
    justify-items: stretch;
    align-items: stretch;
  }

  .searchContainer {
    display: grid;
    grid-template-columns: 1.7fr 1fr 7fr;
    justify-items: center;
    align-items: center;
  }
  



  .incomeChart {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 2rem 0rem;
  }

  .dataTable {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 2rem 0rem 2rem 0rem;
  }

  .loadingContainer {
    width: 70%;
    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .loadingAnimation {
    align-self: start;
  }

  .loadingLabel {
    align-self: end;
  }


  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #2b2c44;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  