
.home {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10%;
    background-color: #2b2c44ff;
}