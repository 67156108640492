* {
  /*border: 1px solid red !important; /**/
}

.App {
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #2a2a2a;
}

.App-link {
  color: #61dafb;
}
